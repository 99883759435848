<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="품명" :value="chemData.mdmChemMaterialName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="자재코드" :value="chemData.materialCd"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="화학자재 일련번호" :value="chemData.mdmChemMaterialId"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param.sync="popupParam"
            :popupParam.sync="popupParam"
            :chemData.sync="chemData"
            :contentHeight="contentHeight"
            @getDetail="getDetail"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chem-msds',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        usageCd: '',  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        msdsNo: '',  // MSDS번호
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고 환산계수
        inConversonUnitCd: null,  // 입고 환산단위
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '',  // 미사용사유
        preMdmChemMaterialId: '',  // 이전화학자재 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      plantCd: '',
      plantName: '',
      materialCd: '',
      materialName: '',
      editable: true,
      tabheight: '',
      tab: 'chemMsdsFile',
      tabItems: [
        { name: 'chemMsdsFile', icon: 'drive_folder_upload', label: 'MSDS', component: () => import(`${'./chemMsdsFile.vue'}`) },
        { name: 'chemSummary', icon: 'warning', label: 'MSDS요약본', component: () => import(`${'./chemSummary.vue'}`) },
        { name: 'chemAppraiseFile', icon: 'drive_folder_upload', label: '유/위해성<br>평가 결과', component: () => import(`${'./chemAppraiseFile.vue'}`) },
        { name: 'chemProcess', icon: 'gavel', label: '취급공정', component: () => import(`${'./chemProcess.vue'}`) },
        { name: 'chemQuantity', icon: 'tag', label: '정량값', component: () => import(`${'./chemQuantity.vue'}`) },
        { name: 'chemLaw', icon: 'gavel', label: '규제사항', component: () => import(`${'./chemLaw.vue'}`) },
        { name: 'chemLicense', icon: 'assignment_turned_in', label: '관련 인허가', component: () => import(`${'./chemLicense.vue'}`) },
        { name: 'chemPhoto', icon: 'photo', label: '제품사진', component: () => import(`${'./chemPhoto.vue'}`) },
        // { name: 'chemDanger', icon: 'warning', label: '위험물 및<br/>지정수량', component: () => import(`${'./chemDanger.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.tabheight = (window.innerHeight - 200) + 'px'
      if (this.popupParam.mdmChemMaterialId) {
        if (this.popupParam.subtabName) {
          this.tab = this.popupParam.subtabName;
        }
      }
    },
    getDetail(selectedId, check) {
      this.$emit('getDetail', selectedId, check)
    },
  }
};
</script>